import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

export const envionment = process.env.NEXT_PUBLIC_APP_ENV;
export const isLocal = envionment === 'local';

export const isBrowser = typeof window !== 'undefined';
export const scrollAtom = atom({
  y: 0,
  x: 0,
  windowHeight: 0,
  documentHeight: 0,
  distFromBottom: 0,
} as {
  y: number;
  x: number;
  windowHeight: number;
  documentHeight: number;
  distFromBottom: number;
  direction?: 'up' | 'down';
});

export const localFeatureFlags = {
  'Universe Animation': false,
};

export const featureFlagsAtom = atomWithStorage('featureFlags', localFeatureFlags);

export const adminModeAtom = atom(true);

export const isEditorialQueueAtom = atom<number[]>([]);
export const isEditorialMapAtom = atom<{ [key: number]: boolean }>({});

export const isEditorialClipQueueAtom = atom<number[]>([]);
export const isEditorialClipMapAtom = atom<{ [key: number]: boolean }>({});

export const randomGridMapAtom = atom<{ [key: string]: any }>({});
