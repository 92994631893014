import { SessionProvider } from 'next-auth/react';
import * as Sentry from '@sentry/nextjs';
import clsx from 'clsx';
import { useAtom } from 'jotai';
import { AppProps } from 'next/app';
import { Router, useRouter } from 'next/router';
import Script from 'next/script';
import { QueryClient, QueryClientProvider } from 'react-query';
import Error from '../components/Error';
import Footer from '../components/layout/Footer';
import NavBar from '../components/layout/NavBar';
import Notification from '../components/layout/Notification';
import ScrollToTop from '../components/shared/ScrollToTop';
import WithRouteGuard from '../components/shared/WithRouteGuard';
import WithAuth from '../components/shared/withAuth';
import LoggedOutNav from '../components/signUp/LoggedOutNav';
import useAdmin from '../hooks/useAdmin';
import useInApp from '../hooks/useInApp';
import useScroll from '../hooks/useScroll';
import { analytics } from '../services/analytics';
import { isLocal } from '../store/store';
import { colorManagerAtom } from '../store/uiStore';
import '../styles/global.css';
import { trpc } from '../utils/trpc';
import HtmlHead from '@/components/shared/atoms/HtmlHead';
import EchosTrigger from '@/components/shared/EchosTrigger';
import MainPageWrapper from '@/components/shared/MianPageWrapper';
import { SpeedInsights } from '@vercel/speed-insights/next';

let timeout;

const sendPageEvent = () => {
  // Only send pageview event from the browser
  if (typeof window === 'undefined') return;

  // don't send pageview event for universe pages
  const { pathname } = window.location;
  if (pathname.startsWith('/universe')) return;
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    analytics?.page();
  }, 500);
};

const handleRouteChange = () => {
  sendPageEvent();
};

Router.events.on('routeChangeComplete', handleRouteChange);

// Trigger the initial page event outside the event handler
if (typeof window !== 'undefined') {
  sendPageEvent();
}

const queryClient = new QueryClient();

function MyApp({ Component, pageProps }: AppProps) {
  const [colorManager] = useAtom(colorManagerAtom);
  const {
    inApp,
    inAccount,
    inCheckout,
    inAdmin,
    inUniverse,
    inProjects,
    inMaintenance,
    inPublicMoodbard,
    inPublicPage,
  } = useInApp();
  const { pathname, query } = useRouter();
  useScroll(true);

  // _app useAdmin is the controller for admin mode toggle
  useAdmin(true);

  //check if url ends with /update-billing - this is specifically for Churnbuster
  const isBilling = pathname.endsWith('/update-billing');
  if (isBilling) {
    return <Component />;
  }

  const renderNavBar = () => {
    if (inPublicMoodbard || inPublicPage) {
      return <></>;
    } else {
      if (inApp) {
        return <NavBar gradient={inProjects} />;
      } else {
        return <LoggedOutNav />;
      }
    }
  };

  const showFooter = !inAccount && !inCheckout && !inAdmin;
  const showHelpscout = !isLocal;

  if (inPublicMoodbard) {
    return (
      <div
        className={clsx(
          colorManager.background,
          colorManager.text,
          'animate-fadeIn transition-colors duration-[300ms] ease-out min-w-[1280px]'
        )}
      >
        <div className={`mx-auto min-h-screen min-w-[1280px] px-[64px]`}>
          <Component {...pageProps} />;
        </div>
        <ScrollToTop />
        <Notification />
      </div>
    );
  }

  return inUniverse ? (
    <Component {...pageProps} />
  ) : (
    <>
      <HtmlHead />
      <div
        className={clsx(
          colorManager.background,
          colorManager.text,
          'animate-fadeIn transition-colors duration-[300ms] ease-out xs:min-w-[1280px]'
        )}
      >
        {/* Handle any errors here */}
        <Sentry.ErrorBoundary fallback={Error}>
          <QueryClientProvider client={queryClient}>
            <SessionProvider session={pageProps.session}>
              {inMaintenance && (
                <div className={`mx-auto min-h-screen min-w-[1280px] px-[64px]`}>
                  <NavBar disableSearchOverlay={true} />
                  <Component {...pageProps} />
                </div>
              )}

              {!inMaintenance && (
                <WithAuth>
                  <WithRouteGuard>
                    <EchosTrigger />
                    <MainPageWrapper>
                      {renderNavBar()}
                      <Component {...pageProps} />
                    </MainPageWrapper>

                    {inApp && (
                      <>
                        <ScrollToTop />
                        {showFooter && <Footer />}
                      </>
                    )}
                  </WithRouteGuard>
                </WithAuth>
              )}
            </SessionProvider>
          </QueryClientProvider>
        </Sentry.ErrorBoundary>
      </div>
      <Notification />
      {/* Load Helpscout script */}
      {showHelpscout && (
        <Script
          id="helpscout-script"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
                        !(function (e, t, n) {
                            function a() {
                                var e = t.getElementsByTagName('script')[0],
                                    n = t.createElement('script');
                                (n.type = 'text/javascript'), (n.async = !0), (n.src = 'https://beacon-v2.helpscout.net'), e.parentNode.insertBefore(n, e);
                            }
                                            if (
                        ((e.Beacon = n =
                        function (t, n, a) {
                            e.Beacon.readyQueue.push({ method: t, options: n, data: a });
                                                    }),
                        (n.readyQueue = []),
                        'complete' === t.readyState)
                        )
                        return a();
                        e.attachEvent ? e.attachEvent('onload', a) : e.addEventListener('load', a, !1);
                        })(window, document, window.Beacon || function () { });
                    `,
          }}
        />
      )}
      {/* Load Microsoft Clarity */}
      {!isLocal && (
        <Script
          id="clarity-script"
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
                        (function(c,l,a,r,i,t,y){
                            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                        })(window, document, "clarity", "script", "${process.env.NEXT_PUBLIC_CLARITY_ID}");
                    `,
          }}
        />
      )}
      {/* Vercel Speed Insights */}
      {!isLocal && <SpeedInsights />}
      {/* Churnkey Script to handle cancel flow */}
      <Script
        id="churnkey script"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
        !function(){
          if (!window.churnkey || !window.churnkey.created) {
            window.churnkey = { created: true };
            const a = document.createElement('script');
            a.src = 'https://assets.churnkey.co/js/app.js?appId=dbaty5xe3';
            a.async = true;
            const b = document.getElementsByTagName('script')[0];
            b.parentNode.insertBefore(a, b);
          }
        }();
      `,
        }}
      />
    </>
  );
}

export default trpc.withTRPC(MyApp);
